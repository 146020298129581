import React from 'react'
import { Helmet } from 'react-helmet'
import '../../../assets/scss/Main.css'
import NavImg from '../../../assets/img/Main/logotest.png'

class MainNav_m extends React.Component {
  render() {
    const style = {
      NavImg: {
        backgroundImage: `url(${NavImg})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    }
    return (
      <div>
        <Helmet>
          <script
            type="text/javascript"
            src="https://ajax.googleapis.com/ajax/libs/jquery/1.10.2/jquery.min.js"
          ></script>
          {/* <link rel="stylesheet" href="css/jquery.mCustomScrollbar.css" /> */}
          <script type="text/javascript" src="/_base.js"></script>
        </Helmet>
        <section className="mo-over-sec">
          <div className="momenu-sec">
            <div class="momenu-top-bg"></div>
            <div class="momenu-logo" data-link="/">
              <img src={NavImg} />
            </div>
          </div>
          <div className="mo-over-sec">
            <div className="momenu-bt">
              <span className="mospan-1"></span>
              <span className="mospan-2"></span>
              <span className="mospan-3"></span>
            </div>
            <div className="over-bg">
              <div className="mo-box">
                <div className="mmo-menubox" >
                  <dl className="mo-mdl">
                    <dt className="mo-dl modt-1" data-link="/">
                      HOME
                    </dt>
                    <dd className="mo-dl modl-1">会社情報</dd>
                    <dd className="mo-dl modl-2">SI事業</dd>
                    <dd className="mo-dl modl-3">採用情報</dd>
                    <dd className="mo-dl modl-4">お問い合わせ</dd>
                    <dt id="mclose" className="mo-dl modt-1">
                      CLOSE
                    </dt>
                  </dl>
                </div>
                <div className="smo smo-menubox1" style={{display:'none'}}>
                  <dl className="mo-sdl-1">
                  <dt className="mo-dl modt-1" data-link="/greetings">
                      会社情報
                    </dt>
                    <dd className="mo-dl modl-1" data-link="/greetings">
                      ご挨拶・会社理念
                      
                    </dd>
                    <dd className="mo-dl modl-2" data-link="/about">
                      会社概要・組織図
                    </dd>
                    <dd className="mo-dl modl-3" data-link="/history">
                      主翼取引先・主要取引先
                    </dd>
                    <dt id="cm-1" className="mo-dl modt-1">
                      戻る
                    </dt>
                  </dl>
                </div>
                <div className="smo smo-menubox2" style={{display:'none'}}>
                  <dl className="mo-sdl-2">
                    <dt className="mo-dl modt-1" data-link="/work01">
                      SI事業
                    </dt>
                    <dd className="mo-dl modl-1" data-link="/work01">
                      開発
                    </dd>
                    <dd className="mo-dl modl-1" data-link="/work02">
                      ネットワーク・セキュリティー
                    </dd>
                    <dd className="mo-dl modl-2" data-link="/work03">
                      モバイル通信
                    </dd>
                    <dd className="mo-dl modl-3" data-link="/work04">
                      システム
                    </dd>
                    <dd className="mo-dl modl-3" data-link="/work05">
                      データベース
                    </dd>
                    <dt id="cm-2" className="mo-dl modt-1">
                      戻る
                    </dt>
                  </dl>
                </div>
                <div className="smo smo-menubox3" style={{display:'none'}}>
                  <dl className="mo-sdl-3">
                    <dt className="mo-dl modt-1" data-link="/recruit01">
                      採用情報
                    </dt>
                    <dd className="mo-dl modl-1" data-link="/recruit01">
                      インフラ
                    </dd>
                    <dd className="mo-dl modl-2" data-link="/recruit02">
                      無線通信
                    </dd>
                    <dd className="mo-dl modl-2" data-link="/recruit03">
                      開発
                    </dd>
                    <dd className="mo-dl modl-2" data-link="/contact">
                      応募登録
                    </dd>
                    <dt id="cm-3" className="mo-dl modt-1">
                      戻る
                    </dt>
                  </dl>
                </div>
                <div className="smo smo-menubox4" style={{display:'none'}}>
                  <dl className="mo-sdl-4">
                    <dt className="mo-dl modt-1" data-link="/access">
                      お問い合わせ
                    </dt>
                    <dd className="mo-dl modl-2" data-link="/access">
                      お問い合わせ
                    </dd>
                    <dt id="cm-4" className="mo-dl modt-1">
                      戻る
                    </dt>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default MainNav_m
