import React from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import { Helmet } from 'react-helmet'
import NavImg from '../../../assets/img/Main/NavImg01.jpg'
// import logo from '../../../assets/img/Main/logotest02.png'
import logo from '../../../assets/img/Main/newLOGO.png'
import '../../../assets/scss/Main.css'

class MainNav extends React.Component {
  render() {
    const style = {
      NavImg: {
        backgroundImage: `url(${NavImg})`,
      },
      LogoImg: {
        backgroundImage: `url(${logo})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
      NavText: {},

      NavTextTop: {
        fontSize: '12px',
        textAlign: 'center',
        marginTop: 0,
        marginBottom: 2,
        color: '#b3b3b3',
      },
      NavTextBottom: {
        fontSize: '21px',
        textAlign: 'center',
        fontWeight:'500',
        marginTop: 0,
        marginBottom: 0,
      },
    }
    return (
      <div>
        <Helmet>
          <script type="text/javascript" src="/jquery-3.3.1.js"></script>
          <script
            type="text/javascript"
            src="/jquery-migrate-1.4.1.js"
          ></script>
          <script
            type="text/javascript"
            src="https://ajax.googleapis.com/ajax/libs/jquery/1.10.2/jquery.min.js"
          ></script>
          <link rel="stylesheet" href="/jquery.mCustomScrollbar.css" />
          <script type="text/javascript" src="/_base.js"></script>
        </Helmet>
        <header className="header" >
          <div className="logo-sec">
            <div className="con-logobox">
              <div className="left-top-box">
                {/* <a href="#" id="favorite" title="お気に入り" >
                  お気に入り
                </a> */}
                <a
                  href="javascript:alert('Cmd + D を押すとお気に入りに追加されます。');"
                  onFocus="this.blur()"
                >
                  ★ お気に入り
                </a>
              </div>
              <div className="right-top-box">
                <a
                  href="#"
                  id="favorite"
                  title="お問い合わせ"
                  data-link="/contact"
                >
                  採用応募登録 ▶️
                </a>
              </div>
            </div>
          </div>
          <div className="head" >
            <div className="con-header">
              <div className="header-box">
                {/* <div className="logo-bx" style={style.LogoImg} data-link="/main"></div> */}
                <div className="logo-bx" data-link="/">
                  <img src={logo} />
                </div>
                <div className="menu-bx">
                  <ul>
                    <li className="header-li li-1" data-link="/greetings">
                      <div>
                        <p style={style.NavTextTop}>COMPANY</p>
                        <p style={style.NavTextBottom}>会社情報</p>
                      </div>
                    </li>
                    <li className="header-li li-2" data-link="/work01">
                      <div>
                        <p style={style.NavTextTop}>WORKS</p>
                        <p style={style.NavTextBottom}>S I 事業</p>
                      </div>
                    </li>
                    <li className="header-li li-3" data-link="/recruit01">
                      <div>
                        <p style={style.NavTextTop}>RECRUIT</p>
                        <p style={style.NavTextBottom}>採用情報</p>
                      </div>
                    </li>
                    <li className="header-li li-4" data-link="/access">
                      <div>
                        <p style={style.NavTextTop}>CONTACT</p>
                        <p style={style.NavTextBottom}>お問い合わせ</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="headersub-sec">
          <div className="con-headersub">
            <div className="headersub-box">
              <div className="left-cont-bx">
                <div className="bg-img-bx" style={style.NavImg}></div>
              </div>
              <div className="right-menu-bx">
                <ul>
                  <li className="headersub-li li-1">
                  <ul className="sub-ul">
                      <li className="sub-li" data-link="/greetings">
                      <span style={{lineHeight:'20px'}}>・ご挨拶<br></br>
                        　会社理念　　　　</span>
                      </li>
                      <p/>
                      <li className="sub-li" data-link="/about">
                      <span style={{lineHeight:'20px'}}>・会社概要　<br></br>
                        　組織図　　　　　</span>
                      </li>
                      <p/>
                      <li className="sub-li" data-link="/history">
                      <span style={{lineHeight:'20px'}}>・会社沿革　<br></br>
                      　主要取引先　　　</span>
                      </li>
                    </ul>
                  </li>
                  <li className="headersub-li li-2">
                    <ul className="sub-ul">
                      <li className="sub-li"  data-link="/work01">
                      ・開発　　　　　
                      </li>
                      <li className="sub-li" data-link="/work02">
                        <span style={{lineHeight:'17px'}}>・ネットワーク<br></br>　セキュリティー</span>
                      </li>
                      <li className="sub-li" data-link="/work03">
                      ・モバイル通信　
                      </li>
                      <li className="sub-li" data-link="/work04">
                      ・システム　　　
                      </li>
                      <li className="sub-li" data-link="/work05">
                      ・データベース　
                      </li>
                    </ul>
                  </li>
                  <li className="headersub-li li-3">
                    <ul className="sub-ul">
                      <li className="sub-li" data-link="/recruit01">
                      ・インフラ　　　
                      </li>
                      <li className="sub-li" data-link="/recruit02">
                      ・無線通信　　　
                      </li>
                      <li className="sub-li" data-link="/recruit03">
                      ・開発　　　　　
                      </li>
                      <li className="sub-li" data-link="/contact">
                      ・応募登録　　　
                      </li>
                    </ul>
                  </li>
                  <li className="headersub-li li-4">
                    <ul className="sub-ul">
                      <li className="sub-li" data-link="/access">
                      ・お問い合わせ{' '}　
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MainNav
