import React from 'react'
import { Helmet } from 'react-helmet'
import '../../../assets/scss/Main.css'
import '../../../assets/scss/jquery.mCustomScrollbar.css'
import logo2 from '../../../assets/img/Main/logo00.png'
import img01 from '../../../assets/img/Main/footer01-2.png'
import img02 from '../../../assets/img/Main/footer02.png'

class Footer extends React.Component {
  render() {
    const style = {
      ImgColor: {
        backgroundColor: 'rgb(231, 235, 243)',
      },
      LogoImg: {
        backgroundImage: `url(${logo2})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
      img01: {
        width: '100%',
        height: '100%',
        marginLeft: '-4%',
      },
      img02: {
        width: '70%',
        height: '100%',
        marginLeft: '18%',
        marginRight: '-9%',
      },
      textColor: {
        color: '#ffffff',
      },
    }

    return (
      <div>
        <div className="foot-sec">
          <div className="foot-map">
            <div className="foot-copy">
              <div className="con-copy">
                <div>
                <a href="/" style={style.textColor}>
                  HOME
                </a>
                <span>　｜　</span>
                <a href="/work01" style={style.textColor}>
                  SI事業
                </a>
                <span>　｜　</span>
                <a href="/recruit01" style={style.textColor}>
                  採用情報
                </a>
                <span>　｜　</span>
                <a href="/access" style={style.textColor}>
                  お問い合わせ
                </a>

                </div>

              </div>
            </div>
            <div className="con-map"  >
              <div className="left-box">
                <img src={img01} className="footImg01"></img>
              </div>
              <div className="mid-box ">
                <img src={logo2} className="footImg02" sizes="contain" />
              </div>
              <div className="mid-box">
                <a href="https://www.fuva-brain.co.jp/" target="_blank">
                  <img src={img02} className="footImg03"></img>
                </a>
                <div>
                  <span className="copyRightMo">
                    {' '}
                    Copyright© GHI Ltd. All Rights Reserved.{' '}
                  </span>
                </div>
              </div>

              <div className="right-box">
                <span className="copyRight">
                  {' '}
                  Copyright© GHI Ltd. All Rights Reserved.{' '}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
